import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import analytics from '@xo-union/tk-analytics';
import {Button} from '@xo-union/tk-component-buttons';
import {Body1} from '@xo-union/tk-ui-typography';
import Icon from '@xo-union/tk-component-icons';
import {
  APPLICATION_GVR,
  APPLICATION_WWS,
  APPLICATION_WWS_OLD_TEMPLATE,
  CASH_REGISTRY_TYPE,
  FEED_REGISTRY_TYPE,
  SOURCE_AFFILIATE_ID_REGISTRY,
  SOURCE_AFFILIATE_ID_WWS,
  FIRST_CASH_FUND_ID,
} from '../../../../constants';
import RetailerLinkWrapper from '../RetailerLinkWrapper';
import RetailerLogo from '../RetailerLogo';
import isPartnerRetailerRegistry from '../../../../utils/isPartnerRetailerRegistry';
import getTrackUrl from '../../../../utils/getTrackUrl';
import getWwsTempVersion from '../../../../utils/getWwsTempVersion';
import isWeddingWebsite from '../../../../utils/isWeddingWebsite';
import style from './style.scss';

const RegistryListItem = ({
  application,
  className,
  hasTransactionalRegistry,
  isMobile,
  memberId,
  registry,
  sourceAffiliateId,
}) => {
  const {
    manualRegistryName,
    manualRegistryUrl,
    id: registryId,
    registryType,
    retailer,
  } = registry;
  const {fullLogoImageUrl, id: retailerId, retailerName} = retailer || {};
  const name = retailerName || manualRegistryName;
  const isCashRegistry = registryType === CASH_REGISTRY_TYPE;
  const isPartnerRegistry = isPartnerRetailerRegistry(retailer);
  const source = application === APPLICATION_GVR ? 'gvr' : 'wws';
  const trackingUrl = isCashRegistry
    ? ''
    : getTrackUrl(manualRegistryUrl, {
        registryId,
        retailerId,
        sourceAffiliateId,
      });

  const handleClick = (e) => {
    e.preventDefault();

    const defaultParams = {
      platform: isMobile ? 'mobile' : 'web',
      product: isWeddingWebsite(application)
        ? APPLICATION_WWS
        : APPLICATION_GVR,
      wwsTempVersion: getWwsTempVersion(application),
    };

    if (memberId) {
      defaultParams.memberId = memberId;
    }

    if (isCashRegistry) {
      analytics.track('Cash Guest Interaction', {
        ...defaultParams,
        selection: 'newlywed fund tile',
        source,
      });

      if (!isMobile) {
        analytics.track('Filter Registry', {
          ...defaultParams,
          availability: null,
          price: null,
          store: 'The Knot Newlywed Fund',
          selection: undefined,
        });
      }

      const firstCashFund = document.getElementById(FIRST_CASH_FUND_ID);
      if (firstCashFund) {
        firstCashFund.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    } else {
      analytics.track('Click-through to Retailer', {
        ...defaultParams,
        registryType:
          parseInt(registryType, 10) === FEED_REGISTRY_TYPE
            ? 'linked'
            : 'manual',
        retailer: name,
        source: 'retailer icon',
      });

      window.open(trackingUrl, '_blank');
    }
  };

  const charLimit = 40;
  const truncatedName =
    name.length > charLimit ? `${name.substring(0, charLimit)}...` : name;

  return hasTransactionalRegistry ? (
    <RetailerLinkWrapper
      className={cx(style['registry-list-item-variant'], className)}
      isCashRegistry={isCashRegistry}
      isPartnerRegistry={isPartnerRegistry}
      onClick={handleClick}
      registryId={registryId}
      retailerId={retailerId}
      source={source}
      trackingUrl={trackingUrl}>
      <div className={style['retailer-name-container']}>
        <Body1 alt={name} bold className={style['retailer-name']}>
          {truncatedName}
        </Body1>
      </div>

      <a
        href={trackingUrl}
        className={style['registry-button']}
        color="primary"
        target="_blank"
        rel="noopener noreferrer"
        size="md">
        Shop Registry{' '}
        <Icon
          name="linkout"
          size="sm"
          role="img"
          className={style['icon-linkout']}
          aria-label="registry link out"
        />
      </a>
    </RetailerLinkWrapper>
  ) : (
    <RetailerLinkWrapper
      className={cx(style['registry-list-item'], className)}
      isCashRegistry={isCashRegistry}
      isPartnerRegistry={isPartnerRegistry}
      onClick={handleClick}
      registryId={registryId}
      retailerId={retailerId}
      source={source}
      trackingUrl={trackingUrl}>
      <div className={style['retailer-name-container']}>
        {isPartnerRegistry && fullLogoImageUrl ? (
          <RetailerLogo
            fullLogoImageUrl={fullLogoImageUrl}
            retailerName={retailerName}
          />
        ) : (
          <Body1 alt={name} bold className={style['retailer-name']}>
            {truncatedName}
          </Body1>
        )}
      </div>

      {isCashRegistry ? (
        <Button
          className={style['registry-button']}
          color="tertiary-alternative"
          size="md">
          View Cash Funds
        </Button>
      ) : (
        <Button
          className={style['registry-button']}
          color="secondary-alternative"
          size="md">
          Shop Registry
        </Button>
      )}
    </RetailerLinkWrapper>
  );
};

const RetailerShape = PropTypes.shape({
  fullLogoImageUrl: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isDeleted: PropTypes.bool.isRequired,
  isPartner: PropTypes.bool.isRequired,
  retailerName: PropTypes.string.isRequired,
});

const CashRegistryShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  registryType: PropTypes.number.isRequired,
  retailer: RetailerShape.isRequired,
});

const ManualRegistryShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  manualRegistryName: PropTypes.string.isRequired,
  manualRegistryUrl: PropTypes.string.isRequired,
  registryType: PropTypes.number.isRequired,
});

const PartnerRegistryShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  manualRegistryName: PropTypes.string.isRequired,
  manualRegistryUrl: PropTypes.string.isRequired,
  registryType: PropTypes.number.isRequired,
  retailer: RetailerShape.isRequired,
});

RegistryListItem.propTypes = {
  application: PropTypes.oneOf([
    APPLICATION_GVR,
    APPLICATION_WWS,
    APPLICATION_WWS_OLD_TEMPLATE,
  ]).isRequired,
  className: PropTypes.string,
  hasTransactionalRegistry: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  memberId: PropTypes.string,
  registry: PropTypes.oneOfType([
    CashRegistryShape,
    ManualRegistryShape,
    PartnerRegistryShape,
  ]).isRequired,
  sourceAffiliateId: PropTypes.oneOf([
    SOURCE_AFFILIATE_ID_REGISTRY,
    SOURCE_AFFILIATE_ID_WWS,
  ]).isRequired,
};

RegistryListItem.defaultProps = {
  className: '',
  hasTransactionalRegistry: false,
  memberId: '',
};

export default RegistryListItem;
